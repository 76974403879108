.graphHoverer {
  outline: var(--space-xxsmall) solid gold;
}
.graphSelecter {
  border: var(--space-xxsmall) solid black;
}
.stack:hover {
  opacity: 0.8;
  outline: var(--space-xxsmall) solid gold;
}
.containLeyend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 100%;
  margin-inline-end: var(--space-xxxlarge);

  p {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-weight: 400;
    font-size: var(--font-size-medium);
    max-height: 130px;
    transform: rotate(180deg);
    opacity: 50%;
  }
}
.xAxisLabel {
  margin-block-start: var(--space-small);

  p {
    color:var(--color-primary);
    font-weight: 400;
    margin-top: var(--space-regular);

  }
}
.stack {
  background-color: var(--background-color) !important;
}
.titleTooltip {
  margin-bottom: 0;
  padding: 0;
  font-weight: 700;
  font-size: var(--space-regular);
  color: var(--gray-medium-color);
}
.scopesTooltip {
  p {
    margin: 0 var(--space-small) var(--space-small) 0;
    color: var(--gray-medium-color);
    font-weight: 400;
    &::before {
        content: '';
        display: inline-block;
        width: var(--space-regular);
        height: var(--space-regular);
        margin-right: var(--space-regular);
    }
    
    &:nth-of-type(1) {
        &::before {
          background-color: var(--color-yellow-light);
        }
    }
    
    &:nth-of-type(2) {
        &::before {
          background-color: var(--color-green);
        }
    }
    
    &:nth-of-type(3) {
        &::before {
          background-color: var(--purple-color);
        }
    }
  }
}