.container {
  display: flex;
  gap: var(--space-medium);
}

.statusContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.statusContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--space-small);
}

.chartContainer {
  flex: 1;
  align-self: center;
}

.chartTitle {
  text-align: center;
}
