.dateSelectorButton {
  border: 1px solid var(--gray-medium-color);
  padding: 0 var(--space-medium);
  outline: 0 !important;
  background-color: transparent;
  border-radius: 20px;

  &:hover {
    border: 1px solid black;
  }
}
