@import "modules/layout/components/MainMenu/MainMenu.module";

.item {
  margin: 15px 0;
  height: 56px;
  display: flex;
  align-items: center;
  width: $sideMenuWidthExpanded * 1px;

  &:hover {
    text-decoration: initial;
  }
}

.itemActive {
  background-color: var(--color-primary-light);
  border-inline-start: 3px solid var(--background-color);

  .text {
    font-weight: 500;
  }
}

.itemSelectable {
  &:hover:not([aria-disabled="true"]) {
    background-color: var(--color-primary-light);
  }

  &:hover:not([aria-disabled="true"]) .text {
    font-weight: 500;
  }
}

.itemContentContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  position: relative;
  width: $sideMenuWidthCollapsed * 1px;
  text-align: center;
}

.text {
  font-weight: 300;
  margin: 0;
}
