.clickable {
  cursor: pointer;
}

.default {
  & td,
  & th {
    background-color: var(--gray-3xlight-color);
  }
}

.primary {
  & td,
  & th {
    background-color: var(--color-primary);
    color: white;
    font-weight: 700;
  }
}

.blue {
  & td,
  & th {
    background-color: var(--color-accent-blue);
    color: white;
    font-weight: 700;
  }
}

.middleBlue {
  & td,
  & th {
    background-color: "var(--color-primary-light)";
    color: "white";
    font-weight: 700;
  }
}

.lightBlue {
  & td,
  & th {
    background-color: "var(--color-primary-background)";
    color: "var(--color-primary)";
  }
}

.white {
  & td,
  & th {
    background-color: "white";
    color: "var(--color-primary)";
  }
}
