.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-medium) var(--space-xlarge);
  background-color: var(--background-color);
  height: var(--space-menu-height);
  border-bottom: var(--space-xsmall) solid var(--gray-xxlight-color);
  z-index: var(--z-index-dashboard-header);
}

.pageHeaderTitleContainer {
  display: flex;
  align-items: center;
}

.pageHeaderLogo {
  margin-inline-end: var(--space-medium);
  margin-block-end: var(--space-xsmall);
  width: 40px;
}

.pageHeaderBranchTitle {
  font-size: var(--font-size-3xlarge);
  color: var(--gray-xdark-color);
  font-weight: 600;
  margin-inline-start: var(--space-xsmall);
}

.pageHeaderTitle {
  margin: 0;
  font-size: var(--font-size-3xlarge);
  color: var(--color-primary);
}

.pageHeaderTitleMain {
  font-weight: 600;
}

.pageHeaderTitleSub {
  font-weight: 400;
}

.pageHeaderContent {
  display: flex;
  align-items: center;
  column-gap: var(--space-medium);
}
