.toasts-container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 15px;
  width: 380px;
  max-width: 100%;
  background-color: transparent;
  z-index: var(--z-index-toast-container);
}

.toast-container.do-style > * {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: black;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  margin-top: 5px;
}
.toast-container.do-style > .variant-primary {
  background-color: var(--primary-color);
  color: white;
}
.toast-container.do-style > .variant-info {
  background-color: var(--info-color);
  color: white;
}
.toast-container.do-style > .variant-danger {
  background-color: var(--danger-color);
  color: white;
}
.toast-container.do-style > .variant-success {
  background-color: var(--success-color);
  color: white;
}
.toast-container.do-style > .variant-warn,
.toast-container.do-style > .variant-warning {
  background-color: var(--warn-color);
  color: black;
}

.toast-container {
  margin-top: 0px;
  transition-duration: 300ms;
  background-color: transparent;
  overflow: hidden;
}
.toast-container > * {
  transition-duration: 300ms;
  transform-origin: top center;
  transform: scale(1);
  padding: 10px 15px;
}
.toast-container.hide {
  margin-top: -5px;
  overflow: visible;
}
.toast-container.hide > * {
  transform: scale(0);
}
