.container {
  display: grid;
  padding: var(--space-medium);
  background: var(--gray-3xlight-color);
  border-radius: var(--space-medium);
  border: 0;
  outline: 0;
  position: relative;
}

.multiLine {
  display: flex;
  row-gap: var(--space-medium);
  flex-direction: column;
  justify-content: space-between;
}

.white {
  background-color: white;
}

.primary {
  background: var(--color-primary);
}

.small {
  padding: var(--space-xsmall) var(--space-small);
}

.smallY {
  padding-block: var(--space-small);
}
