.header {
  display: flex;
  align-items: flex-start;
  gap: var(--space-medium);
  flex: 0 0 auto;
  color: var(--gray-medium-color);
  font-weight: 700;
  padding-block-end: var(--space-normal);
  background-color: white;

  &.color--primary {
    color: var(--color-primary);
  }
}

.closeBtn {
  margin-inline-start: auto;
  display: flex;
  color: inherit;
}
