.headerText {
  font-size: var(--font-size-xxlarge);
  font-weight: 600;
  margin: 0;
}

.hint {
  color: var(--color-primary-light);
  font-size: var(--font-size-large);
  margin-block-end: var(--space-xlarge);
}

.cancelButton {
  margin-inline-end: var(--space-normal);
}
