.isExpanded {
  .btn {
    background-color: var(--gray-xxlight-color);
    color: var(--gray-medium-color);

    .chevron {
      transform: rotate(180deg);
    }
  }

  .children {
    max-height: 1000vh;
    overflow-y: auto;
  }
}

.variant--primary {
  .btn {
    background-color: var(--color-primary);
  }

  &.isExpanded {
    .btn {
      background-color: var(--color-primary-light);
      color: var(--text-color-inverse);
    }
  }
}

.variant--condensed {
  &,
  &.isExpanded {
    border-block-end: 1px solid var(--color-primary-xlight);

    &:not(:first-child) {
      margin-block-start: var(--space-small-negative);
    }

    .btn {
      padding: var(--space-normal);
      margin: 0;
      color: var(--color-primary);
    }

    .children {
      padding-inline: var(--space-normal);

      > *:last-child {
        padding-block-end: var(--space-normal);
      }
    }
  }

  &,
  .btn {
    background-color: white;
    transition: all 0.2s linear 0.1s;
  }

  &.isExpanded {
    &,
    .btn {
      background-color: var(--color-primary-3xlight);
    }
  }
}

.variant--plain {
  &,
  &.isExpanded {
    &:not(:first-child) {
      margin-block-start: var(--space-small-negative);
    }

    .btn {
      padding: var(--space-normal);
      margin: 0;
      color: var(--color-primary);
    }

    .children {
      padding-inline: var(--space-normal);

      > *:last-child {
        padding-block-end: var(--space-normal);
      }
    }
  }

  &,
  .btn {
    background-color: var(--background-color);
    transition: all 0.2s linear 0.1s;
    font-weight: 500;
    font-size: var(--font-size-xlarge);
    border-bottom: 1px solid var(--color-primary-background);
  }
}

.btn {
  display: flex;
  padding: var(--space-xsmall) var(--space-medium);
  align-items: center;
  gap: var(--space-xsmall);
  border-radius: var(--space-xsmall);
  font-weight: 600;
  background-color: var(--color-primary-light);
  color: white;
  margin-block-end: var(--space-small);
  width: 100%;

  &.btnCustom {
    padding: 0;
    background-color: transparent;
    font-weight: inherit;
  }
}

.chevron {
  margin-inline-start: auto;
  transition: all 0.3s;
}

.children {
  padding: 0;
  list-style: none;
  overflow-y: clip;
  transition: all 0.2s;
  max-height: 0;
}
