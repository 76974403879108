.btn {
  color: var(--color-primary);
  background-color: var(--gray-3xlight-color);
  outline: none;
  border-radius: var(--space-small);
  padding: var(--space-medium);
  font-weight: 700;
  font-size: var(--font-size-medium);
  text-align: center;
  border: var(--space-xxsmall) solid;
  border-color: var(--gray-3xlight-color);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.primary {
  color: white;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.secondary {
  font-weight: 400;
  background-color: var(--color-primary-background);
  border-color: var(--color-primary-background);
}

.blue {
  color: white;
  background-color: var(--color-accent-blue);
  border-color: var(--color-accent-blue);

  &:hover {
    color: white;
    background-color: var(--color-accent-blue) !important;
    border-color: var(--color-accent-blue) !important;
  }
}

.white {
  font-weight: 400;
  background-color: white;
}

.outline {
  font-weight: 400;
  background-color: transparent;
  outline: var(--space-xxsmall) solid var(--color-primary-xxlight) !important;
  outline-offset: var(--space-xxsmall-negative);
  border: var(--space-xxsmall) solid var(--color-primary-xxlight);
}

.outline-minor {
  font-weight: 500;
  background-color: transparent;
  border: var(--space-xxsmall) solid var(--gray-light-color);
  color: var(--gray-xdark-color);
  border-radius: var(--space-medium);
  padding: var(--space-small) var(--space-normal);
  font-size: var(--font-size-regular);
}

.link {
  outline: none !important;
  border-color: transparent transparent var(--color-primary);
  border-radius: 0;
  padding: 0 !important;
}

.danger {
  font-weight: 400;
  background-color: var(--danger-color);
  color: var(--text-color-inverse);
  border-color: var(--danger-color);
}

.size--small {
  padding: var(--space-small);
}

.size--large {
  padding: 11px;
  font-size: var(--font-size-large);
}

.size--xlarge {
  padding: 11px;
  line-height: 19.2px;
  font-size: var(--font-size-2xlarge);
  font-weight: var(--font-weight-600);
}
