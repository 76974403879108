.row {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  color: var(--color-primary);
  gap: var(--space-medium);
  cursor: pointer;
  border: 1px solid transparent;
  width: 100%;

  & > :nth-child(1) {
    width: 121px;
    flex: 0 0 auto;
  }

  & > :nth-child(2) {
    flex: 1;
  }

  & > :nth-child(3) {
    width: 80px;
    flex: 0 0 auto;
    text-align: end;
  }

  &.rowExpanded {
    background-color: var(--color-primary-xxlight);
    border: 1px solid var(--color-primary);
  }
}

.breakdown {
  margin-block: var(--space-medium);
}
