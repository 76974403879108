.container {
  display: flex;
  align-items: center;
  gap: var(--space-medium);

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-xsmall);
    display: flex;
    width: 100%;

    & > :nth-child(1) {
      margin-top: var(--space-small);
      order: 1;
    }
  }

  &,
  & * {
    overflow: visible;
  }
}

.meterContainer {
  display: flex;
  flex: 1 1 100%;
  position: relative;
  width: 100%;
}

.meter {
  width: 100%;
  appearance: none;
  height: 5px;
  border-radius: 5px;
  min-width: var(--space-xxxlarge);
  position: relative;
}

.pointer {
  position: absolute;
  font-size: var(--font-size-xxsmall);
  transition-duration: 250ms;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  top: var(--space-small-negative);
}

.value {
  font-size: var(--font-size-large);
  font-weight: 500;
}

.overflowHidden {
  overflow: hidden;
}

.topIndicators {
  display: flex;
  position: absolute;
  top: -10px;
  width: 100%;
  min-width: var(--space-xxxlarge);
  justify-content: space-between;
  font-size: var(--font-size-small);
  font-weight: 400;
}
