$sideMenuWidthCollapsed: 56;
$sideMenuWidthExpanded: 256;

:export {
  sideMenuWidthCollapsed: $sideMenuWidthCollapsed;
}

.sideMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: $sideMenuWidthCollapsed * 1px;
  background: var(--color-primary);
  color: var(--text-color-inverse);

  &:hover {
    width: $sideMenuWidthExpanded * 1px;
  }

  transition: all 0.2s ease;
}

.staticSideMenu {
  &:hover {
    width: $sideMenuWidthCollapsed * 1px;
  }
}

.sideMenuLogo,
.sideMenuTopLogo {
  width: 40px;
  min-width: 40px;
  margin: var(--space-medium);
}

.sideMenuTopLogo {
  margin-block-start: var(--space-xlarge);
}

button.sideMenuLanguage {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0 !important;
  color: var(--text-color-inverse);
  background-color: transparent;
  font-size: var(--font-size-large);

  &:disabled {
    color: var(--text-color-inverse);
    background-color: transparent;
    opacity: 1;
    cursor: not-allowed;
    font-weight: bold;
  }
}

.titleContainer {
  height: 35px;
  margin: var(--space-xlarge) 0 0 var(--space-xlarge);

  & > h4 {
    font-weight: 300;
  }
}

.sideMenuMidBottomSpacer {
  flex: 5;
}

.sideMenuTop {
  display: flex;
  align-items: center;
  gap: var(--space-medium);
  width: 100%;
  height: var(--space-menu-height);

  & > h4 {
    margin: 0;
  }
}

.divider {
  width: 50%;
  background-color: var(--gray-3xlight-color);
  height: 0.1px;
  margin: var(--space-medium) auto;
  opacity: 0.2;
}

.sideMenuTop,
.sideMenuBottom {
  flex-shrink: 0;
}

.sideMenuBottom {
  margin-inline: var(--space-xsmall);
}

.sideMenuMid {
  overflow: hidden auto;
  margin-block-start: var(--space-xxxlarge);

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.sideMenuLanguages {
  text-align: center;
  margin-block-end: var(--space-medium);
  max-width: 50px;
}

.sideMenuUserButton {
  background-color: transparent;
  border: 0;
  margin-bottom: var(--space-medium);
  margin-inline: auto;
  outline: 0 !important;
  display: block;
}

.logOutButton,
.clearCacheButton,
.switchButton {
  background-color: transparent;
  border: 0;
}

.userFloatingContainer {
  white-space: nowrap;
}

.userFloatingContainerAdminNote {
  color: gray;
  font-size: var(--font-size-small);
  font-style: italic;
  text-align: start;
}

.sideMenuUserContainer {
  display: flex;
  align-items: center;
  gap: var(--space-xlarge);

  & > h4 {
    color: var(--text-color-inverse);
    font-size: var(--font-size-regular);
  }
}

.sideMenuUserLogo {
  width: fill-available;
  width: 40px;
}
