.plot-with-labels,
.plot-with-labels * {
  overflow: visible;
}

.plot-with-labels {
  position: relative;
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-template-rows: max-content auto max-content;
}
.plot-with-labels > * {
  min-width: 0;
  min-height: 0;
}

.plot-with-labels__plot,
.plot-responsive-container {
  position: relative;
}

.plot-with-labels__x-label,
.plot-with-labels__y-label,
.plot-with-labels__x-label *,
.plot-with-labels__y-label * {
  display: inline-block;
}

.plot-with-labels__y-label {
  height: 0px;
  display: block;
  text-align: end;
  padding-inline-end: var(--space-small);
}
.plot-with-labels__y-labels--alternate .plot-with-labels__y-label {
  text-align: start;
  padding-inline-end: 0;
  padding-inline-start: var(--space-small);
}
.plot-with-labels__y-label > * {
  transform: translateY(-50%);
}
.plot-with-labels__y-label--start > * {
  transform: translateY(0);
  font-weight: 600;
}
.plot-with-labels__y-label--end > * {
  transform: translateY(-100%);
}
.plot-with-labels__x-label {
  width: 0px;
  vertical-align: top;
}
.plot-with-labels__x-labels--alternate .plot-with-labels__x-label {
  vertical-align: bottom;
}
.plot-with-labels__x-label > * {
  transform: translateX(-50%);
}
.rtl .plot-with-labels__x-label > * {
  transform: translateX(50%);
}
.plot-with-labels__x-label--start > * {
  transform: translateX(0);
  font-weight: 600;
}
.plot-with-labels__x-label--end > * {
  transform: translateX(-100%);
}
.rtl .plot-with-labels__x-label--end > * {
  transform: translateX(100%);
}

.plot-with-labels__y-label > *,
.plot-with-labels__x-label > * {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
