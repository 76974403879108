.form {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-medium);
}

.controls {
  display: flex;
  gap: var(--space-medium);
  align-items: center;
}
