.info-popover-button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}

.popovers-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: -100%;
  z-index: var(--z-index-popover);
  overflow: visible;
}

.info-popover__popover-container {
  background-color: wheat;
  color: black;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 10px;
  z-index: var(--z-index-info-popover);
  overflow: visible;
}

.info-popover__popover-container--light {
  background-color: white;
  border-radius: 4px;
}

.info-popover__close-button-container {
  display: flex;
  justify-content: flex-end;
  padding: var(--space-xsmall);
  cursor: pointer;
}

.info-popover__primary {
  background: var(--color-primary);
  border: 0;
  color: var(--text-color-inverse);
  padding: var(--space-medium) var(--space-normal);
  max-width: 180px;
  white-space: break-spaces;
  font-weight: 400;
}

.info-popover__dark {
  background: var(--gray-xxxdark-color);
  border: 0;
  color: var(--text-color-inverse);
  padding: var(--space-medium) var(--space-normal);
  max-width: 180px;
  white-space: break-spaces;
  font-weight: 400;
}

.info-popover__card {
  background: var(--background-color);
  border-radius: 8px;
  border: 1px solid #dddfdf;
  color: var(--text-color);
  padding: var(--space-medium) var(--space-normal);
  max-width: 180px;
  white-space: break-spaces;
  font-weight: 400;
}
