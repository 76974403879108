.h {
  color: var(--color-primary);
  font-size: var(--font-size-regular);
  font-weight: 600;
  margin: 0;
  padding: 0 0 var(--space-medium);
  background-color: transparent;
  border: 0;
  outline: 0;

  & > *:not(button) {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }

  &.weight--normal {
    font-weight: normal;
  }

  &.weight--bold {
    font-weight: 600;
  }
}

.weight--normal {
}

.weight--bold {
}

.withSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-small);
  font-weight: normal;
}
