.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
}

.chartContainer {
  margin: 0 var(--space-xxlarge);
  aspect-ratio: 0.995;
  overflow: visible;

  > * {
    overflow: visible;
  }
}

.legendsContainer {
  font-size: var(--font-size-medium);
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: var(--space-medium);
  white-space: nowrap;
  background-color: white;
  border-radius: var(--space-xsmall);
  padding: var(--space-xsmall) var(--space-small);
  border: 1px solid var(--gray-xxlight-color);
  color: var(--color-primary);
}

.legend {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}

.legendColor {
  display: block;
  width: var(--space-small);
  height: var(--space-small);
  border-radius: 50%;
}

.tooltip {
  font-weight: 700;
  color: var(--color-primary);
  white-space: nowrap;
}

.variant--black {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: var(--color-primary-dark);
  color: var(--text-color-inverse);
  position: sticky;
  margin: 0;
  z-index: var(--z-index-main-tip);
  min-width: 100px;
  border-radius: var(--space-medium);
  font-size: var(--font-size-large);
  font-weight: 500;
  line-height: 20px;

  span {
    margin-inline-start: var(--space-small);
    min-width: 20px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--space-small);

    p {
      margin: 0;
      font-size: var(--font-size-regular);
      color: var(--text-color-inverse);
    }
  }
}

.legendTitle {
  font-size: var(--font-size-2xlarge);
  font-weight: var(--font-weight-600);
  margin-bottom: var(--space-regular);
}

.svgContainer {
  position: relative;

  .pieValue {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--font-size-7xlarge);
    font-weight: var(--font-weight-600);
    letter-spacing: -0.02em;
  }
}
