.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.centerPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.model {
  flex: 1;
}

.focusedNote {
  display: flex;
  position: absolute;
  bottom: var(--space-medium);
  inset-inline-end: var(--space-medium);
  max-width: 50%;
  width: 250px;
  padding: var(--space-medium);
  background-color: var(--info-color);
  border-radius: var(--space-medium);
  align-items: center;
  color: white;

  > button {
    color: "white";
  }
  
  & > :first-child {
    flex: 1;
  }
}
