/* stylelint-disable declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix */

/* Reboot */
*,
*::before,
*::after {
  box-sizing: border-box;
  line-height: 120%;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

html {
  font-family: "Inter", sans-serif;
  line-height: 1.15;
  font-size: 62.5%;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

/* Body */
body {
  margin: 0;
  text-align: left;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

/* Content grouping */
hr {
  height: 0;
  overflow: visible;
  box-sizing: content-box;
  border-color: rgb(255 255 255 / 15%);
}

/* Typography */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h6,
h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

p {
  margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
}

small {
}

sub,
sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Links */
a,
a:hover,
a:active,
a:link {
  color: unset;
  background-color: transparent;
}

a:not([href], [class]) {
  color: inherit;
  text-decoration: none;
}

/* Code */
pre,
code,
kbd,
samp {
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

/* Figures */
figure {
  margin: 0 0 1rem;
}

/* Images and content */
img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

/* Tables */
table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

/* Forms */
label {
  display: inline-block;
  margin: 0;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  &:not(:disabled) {
    cursor: pointer;
  }
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
}

::-webkit-file-upload-button {
  font: inherit;
}

/* Correct element displays */
output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

/* Always hide an element with the `hidden` HTML attribute */
[hidden] {
  display: none !important;
}
