.sliderContainer {
  --thumb-diameter: var(--space-normal);

  position: relative;
  overflow: visible;
}

.sliderSlider {
  border-radius: var(--space-small);
  margin-block: var(--space-medium);
  height: var(--space-small);
  width: 100%;
  appearance: none;
  overflow: visible;
  background: var(--gray-xxlight-color);

  &::-webkit-slider-thumb {
    border: 3px solid var(--color-primary-light);
    appearance: none;
    height: var(--thumb-diameter);
    width: var(--thumb-diameter);
    border-radius: 50%;
    background: white;
    cursor: ew-resize;
  }

  &::-moz-range-thumb {
    border: 3px solid var(--color-primary-light);
    height: var(--thumb-diameter);
    width: var(--thumb-diameter);
    border-radius: 50%;
    background: white;
    cursor: ew-resize;
  }
}

.sliderSlider[disabled] {
  background: var(--gray-xlight-color);

  &::-webkit-slider-thumb {
    background: var(--gray-light-color);
  }

  &::-moz-range-thumb {
    background: var(--gray-light-color);
  }

  & ~ .timesliderLabel {
    color: var(--gray-light-color);
  }

  &::-webkit-slider-thumb {
    border: 3px solid var(--gray-light-color);
  }

  &::-moz-range-thumb {
    border: 3px solid var(--gray-light-color);
  }
}

.sliderSliderValue {
  position: absolute;
  inset-block-end: var(--space-xxlarge);
  background-color: var(--color-primary-light);
  padding: var(--space-small) var(--space-normal);
  color: var(--text-color-inverse);
  border-radius: 10px;
  overflow: visible;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-block-start: 4px solid var(--color-primary-light);
    border-inline-start: 5px solid transparent;
    border-inline-end: 5px solid transparent;
    inset-block-start: 100%;
    inset-inline-start: 50%;
    margin-inline-start: -5px;
    margin-block-start: -1px;
  }
}

.sliderLabel {
  display: block;
  text-align: center;
  color: var(--color-primary);
}

.sliderColoredBar {
  background: var(--color-primary-light);
  border-radius: var(--space-small) 0 0 var(--space-small);
  margin-block: var(--space-medium);
  height: var(--space-small);
  position: absolute;
}
