.fieldElementRowContainer {
  background-color: var(--color-grey-light);
  border-radius: 4px;
  margin-block: var(--space-small) var(--space-medium);
}

.fieldElementRow {
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-medium);
  width: 100%;
  height: 32px;
  border-radius: 4px;
  padding: var(--space-small) var(--space-medium);
  font-size: var(--font-size-large);

  & > :nth-child(1) {
    flex: 4.3;
  }

  & > :nth-child(2) {
    margin-inline-end: var(--space-xlarge);
    text-align: center;
    flex: 1;
  }

  & > :nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    flex: 1.5;

    & > :nth-child(1) {
      flex: 1;
      text-align: center;
      margin-inline-start: var(--space-medium-negative);
    }

    & > :nth-child(2) {
      flex: 1;
      text-align: center;
    }
  }
}

.subFieldElementRow {
  & > :nth-child(1) {
    margin-inline: var(--space-xxlarge) var(--space-xxlarge-negative);
  }

  & > :nth-child(2) {
    margin-inline-end: var(--space-medium);
  }

  & > :nth-child(3) {
    overflow: visible;

    & > :nth-child(1) {
      width: 75px;
    }

    & > :nth-child(2) {
      margin-inline-end: var(--space-medium-negative);
    }
  }
}

.modifyScoreIconsContainer {
  display: flex;
  gap: var(--space-small);
  margin-inline: var(--space-medium);
}

.notAllowed {
  cursor: not-allowed !important;
}

.fieldTitle {
  margin-inline: var(--space-medium);
}
