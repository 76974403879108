.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-medium);
}

.meter {
  flex: 1 1 100%;

  &::-webkit-meter-bar {
    background-color: var(--background-color);
    border: none;
    height: 5px;
    border-radius: 5px;
  }

  &::-moz-meter-bar {
    background-color: var(--background-color);
    border: none;
    height: 5px;
    border-radius: 5px;
  }

  &::-webkit-meter-optimum-value {
    background-color: var(--color-primary);
    border-radius: 5px;
  }

  &:-moz-meter-optimum::-moz-meter-bar {
    background-color: var(--color-primary);
    border-radius: 5px;
  }
}

.meter {
  &.big {
    border-radius: 50px;

    &::-webkit-meter-bar {
      height: 10px;
      border-radius: 50px;
      background-color: var(--color-primary-xxlight);
    }

    &::-moz-meter-bar {
      height: 10px;
      border-radius: 50px;
      background-color: var(--color-primary-xxlight);
    }

    &::-webkit-meter-optimum-value {
      background-color: var(--color-primary-muted);
      border-radius: 50px;
    }

    &:-moz-meter-optimum::-moz-meter-bar {
      background-color: var(--color-primary-muted) a;
      border-radius: 50px;
    }
  }
}

.indicatorValue {
  color: var(--color-primary);
  margin: 0;
  font-weight: 700;
  overflow: visible;
}
