.headerText {
  font-size: var(--font-size-xxlarge);
  font-weight: 600;
  margin: 0;
}

.hint {
  color: var(--color-primary-light);
  font-size: var(--font-size-large);
  margin-block-end: var(--space-large);
}

.targetContainer {
  font-size: var(--font-size-large);
  padding: var(--space-normal) var(--space-normal) var(--space-xlarge);
  display: flex;
  flex-direction: column;
  row-gap: var(--space-normal);
}

.totalCredits {
  font-size: var(--font-size-xlarge);
  color: var(--color-primary);
  padding: var(--space-medium);
  border: 1px solid var(--color-primary-xlight);
  border-radius: var(--space-small);
  background-color: var(--color-primary-3xlight);
  display: flex;
  justify-content: space-between;
}

.error {
  text-align: center;
  font-size: var(--font-size-large);
  color: var(--danger-color);
}

.form {
  margin-inline: var(--space-normal-negative);
  margin-block-end: var(--space-normal);
}

.accordion {
  overflow: visible;
  font-size: var(--font-size-xxlarge);
}

.footer .btn {
  font-size: var(--font-size-large);
}
