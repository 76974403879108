.cell {
  padding: var(--space-xsmall);
  text-align: start;

  &:first-child {
    border-radius: var(--space-small) 0 0 var(--space-small);
  }

  &:last-child {
    border-radius: 0 var(--space-small) var(--space-small) 0;
  }
}