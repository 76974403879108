.tabs {
  display: flex;
  gap: var(--space-small);
  justify-content: center;

  &.growTabs {
    & > * {
      flex: 1;
    }
  }
}

.growTabs {
}

.panel {
  margin-block-start: var(--space-medium);
}

.panelBorder {
  padding: var(--space-medium);
  border: var(--space-xxsmall) solid var(--gray-xxlight-color);
  border-radius: var(--space-medium);
}

.underlined {
  gap: 0;
}

.box {
  gap: 0;

  & > * {
    margin-inline: var(--space-xxsmall-negative);
  }
}

.rounded {
  background-color: var(--gray-3xlight-color);
  border-radius: 50rem;
  padding: var(--space-small);
}

.sleek {
  background-color: var(--color-primary-xxlight);
  padding: var(--space-xsmall);
  border-radius: var(--space-medium);
}

.panelsmall {
  margin-block-start: var(--space-large);
}
