.alert {
  border-radius: var(--space-small);
  padding: var(--space-medium);
}

.warning {
  color: var(--color-warn-dark);
  background-color: var(--lighting-yellow-color);
  border: var(--space-xxsmall) solid var(--color-yellow-light);
}
