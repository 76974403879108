.table {
  tr {
    & > :first-child {
      width: 50px;
    }

    & > :nth-child(2) {
      width: 80px;
    }

    td:first-child > :first-child {
      margin-left: var(--space-small);
    }
  }

  .tableHeader {
    height: 25px;
  }

  .tableCellCentered {
    text-align: center;
  }
}
