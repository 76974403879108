.button {
  padding: var(--space-medium);
  outline: 0;
  border-radius: 8px;
  border: 1px solid var(--color-primary-light);
  display: flex;
  align-items: center;
  gap: var(--space-medium);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.buttonActive {
  background-color: var(--color-primary-light);
  color: white;
}

.buttonInactive {
  background-color: white;
  color: var(--color-primary-light);
}
