.container {
  width: 100%;
}

.label {
  color: var(--color-primary);
  font-size: var(--font-size-xlarge);
  margin-block-end: var(--space-small);
}

.inputContainer {
  position: relative;
  width: 100%;

  input {
    padding: var(--space-medium) var(--space-large) var(--space-medium) var(--space-medium);
    border: 1px solid var(--color-primary-xxlight);
    border-radius: 4px;
    font-size: var(--font-size-xlarge);
    color: var(--color-primary);
    width: 100%;
  
    &::placeholder {
      color: var(--color-primary-xlight);
      font-weight: 400;
    }
  
    &:focus-visible {
      outline: none;
    }
  
    &.icon {
      padding-left: 28px;
    }
  }
}
