.label {
  --radius: var(--space-normal);
  line-height: 0;
  display: flex;
  gap: var(--space-small);
  cursor: pointer;
  align-items: flex-start;
}

.reversed {
  flex-direction: row-reverse;
}

.input {
  appearance: none;
  background-color: var(--gray-4xlight-color);
  margin: 0;
  width: var(--radius);
  height: var(--radius);
  border: var(--space-xxsmall) solid var(--gray-light-color);
  border-radius: 50%;
  display: grid;
  place-content: center;
  flex-shrink: 0;
  cursor: pointer;

  &::before {
    content: "";
    width: calc(var(--radius) - 6px);
    height: calc(var(--radius) - 6px);
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--gray-4xlight-color);
  }

  &:checked {
    background-color: var(--color-primary);

    &::before {
      transform: scale(1);
    }
  }
}
