.noScenariosContainer {
  background-color: var(--color-primary-3xlight);
  border-radius: var(--space-small);
  border: 1px solid var(--color-primary-xxlight);
  color: var(--color-primary);
  font-size: var(--font-size-large);
  font-weight: bold;
  padding: var(--space-small);
  margin-block: var(--space-small);
}
