.wizardButton {
  width: 100%;
  padding-block: var(--space-normal);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-small);
}

.buttonText {
  font-size: var(--font-size-large);
  font-weight: 500;
}

.modal {
  width: 450px;
  padding: var(--space-large);
  display: flex;
  flex-direction: column;
  gap: var(--space-normal);
}

.subText {
  color: var(--text-color-muted);
  font-weight: 400;
}
