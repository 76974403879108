.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: var(--space-medium);
  flex: 1;
}

.title {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  color: var(--gray-medium-color);
  font-weight: 700;
  flex: 1;
}

.value {
  border: 2px solid;
  border-radius: var(--space-medium);
  padding-block: var(--space-small) var(--space-small);
  background-color: var(--gray-xxlight-color);
  text-align: center;
}

