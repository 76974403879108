.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--color-primary);

  & > p {
    font-size: var(--font-size-xlarge);
    margin: 0;
    padding-inline-end: var(--space-xxxlarge);
  }
}

.actionButton {
  font-weight: 400;
  margin-inline-start: var(--space-medium);
}
