.floatingContainer {
  background-color: var(--text-color-inverse);
  padding: var(--space-large);
  z-index: var(--z-index-date-selector);
  border-radius: var(--space-normal);
  box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
  width: 360px;

  :global(.react-calendar) {
    border: 0;
    background-color: transparent;
  }

  :global(.react-calendar__navigation button) {
    text-align: center;
  }
}

.rawSelectors {
  text-align: center;
  margin-block: var(--space-medium);
}

.rawSelector {
  margin: 0 var(--space-small);
  padding: 0 var(--space-medium);
  outline: 0 !important;
  border: 0;
  background-color: transparent;
  font-size: var(--font-size-xxlarge);
  font-weight: 600;
  color: var(--color-primary);

  &:hover {
    cursor: pointer;
    color: var(--color-primary-dark);
  }

  &:disabled {
    color: var(--color-primary-light);
    cursor: not-allowed;
  }
}

.rawSelectorSelected {
  color: var(--color-accent-blue);
}

.calendar {
  // top tiles for days
  & :global(.react-calendar__month-view__weekdays__weekday) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-large) 0;
    font-size: var(--font-size-xxlarge);
    font-weight: 600;
    color: var(--color-primary-text);
    text-transform: capitalize;

    & abbr {
      text-decoration: none;
    }
  }

  // first and last tiles in selected range
  & :global(.react-calendar__tile--rangeStart),
  & :global(.react-calendar__tile--rangeEnd) {
    color: var(--text-color-inverse) !important;

    > abbr {
      z-index: 1;
    }
    > div {
      border-radius: 50%;
      background-color: var(--color-primary);
    }
  }

  // first tile in selected range
  & :global(.react-calendar__tile--rangeStart) {
    border-start-start-radius: 50%;
    border-end-start-radius: 50%;
  }

  // last tile in selected range
  & :global(.react-calendar__tile--rangeEnd) {
    border-start-end-radius: 50%;
    border-end-end-radius: 50%;
  }

  // other tiles in selected range
  & :global(.react-calendar__tile--active),
  & :global(.react-calendar__tile--rangeEnd) {
    background-color: var(--color-primary-background);

    &:hover,
    &:enabled {
      background-color: var(--color-primary-background);
    }
  }

  // tile in the hovered range
  & :global(.react-calendar__tile--hover) {
    background-color: var(--color-primary-background);
  }

  // main label
  & :global(.react-calendar__navigation__label) {
    font-size: var(--font-size-3xlarge);
    font-weight: 600;
    color: var(--color-primary-text);
  }

  // navigation arrows
  & :global(.react-calendar__navigation__arrow) {
    font-size: 20px;
  }
}

.calendar .tile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-large) 0;
  font-size: var(--font-size-xxlarge);
  color: var(--color-primary-text);

  &:hover {
    background-color: var(--color-primary-background);
    border-radius: 50%;
  }

  &:disabled {
    &,
    &:hover {
      color: var(--color-primary-light);
      background-color: var(--background-color);
    }
  }
}

.calendarWithFixedRange {
  .tileHighlighted:not(:disabled) {
    &,
    &:hover {
      background-color: var(--calendar-fixed-range-hover);

      &:global(.react-calendar__tile--active),
      &:global(.react-calendar__tile--hasActive) {
        background-color: var(--calendar-fixed-range-active-hover);
      }
    }
  }
}

.highlighter {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
}

.divider {
  margin-inline-start: var(--space-large-negative);
  width: calc(100% + var(--space-xxlarge));
}

.actionButtonsContainer {
  display: flex;
  gap: var(--space-medium);
}

.actionButton {
  flex: 1;
  border-radius: var(--space-medium);
  font-weight: var(--font-weight-400);
}
