.dialog {
  overflow-y: auto;
  color: var(--color-primary);
  background-color: white;
  border-radius: var(--space-medium);
  border: none;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 25%);
  max-width: 100%;
  padding: var(--space-normal);
  display: flex;
  flex-direction: column;
  position: relative;

  &:focus-visible {
    outline: none;
  }

  > * {
    flex: 0 0 auto;
  }

  > header {
    position: sticky;
    inset-block-start: 0;
    transform: translateY(var(--space-normal-negative));
    margin-block-end: var(--space-normal-negative);
    padding-block-start: var(--space-normal);
    padding-inline: var(--space-normal);
    margin-inline: var(--space-normal-negative);
    z-index: var(--z-index-modal-header);
  }

  &.footerSticky > footer {
    position: sticky;
    inset-block-end: 0;
    transform: translateY(var(--space-normal));
    margin-block-start: var(--space-normal-negative);
    padding-block-end: var(--space-normal);
    padding-inline: var(--space-normal);
    margin-inline: var(--space-normal-negative);
  }

  &.isSuccess {
    &,
    > header,
    > footer {
      color: var(--gray-xdark-color);
      background-color: var(--color-success);
    }
  }

  &.width--medium {
    width: 448px;
  }

  &.width--large {
    width: 560px;
  }
}
