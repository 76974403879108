.strippedButton {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0 !important;
  background-color: transparent;

  &.flex {
    display: inline-flex;
  }
}
