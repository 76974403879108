.container {
    background-color: var(--background-color);
    padding: var(--space-large);
    position: "relative";
    .closeBtn {
        position: absolute;
        top: var(--space-small);
        inset-inline-end: var(--space-medium);
        padding: 0;
        margin: 0;
        border: 0;
        background-color: "transparent";
        outline: 0 !important;
    }
}