.element {
  display: block;
  width: 100%;
  padding: var(--space-xsmall) var(--space-medium) var(--space-xsmall) var(--space-large);
  border-radius: var(--space-xsmall);
  background-color: var(--color-primary-3xlight);
  color: var(--color-primary);
  margin-block-start: var(--space-small);

  &.elementClickable {
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
      text-decoration: none;
    }
  }

  &.elementSelected {
    background-color: var(--color-primary-xxlight);
    outline: var(--space-xxsmall) solid var(--color-primary-xlight) !important;
    outline-offset: var(--space-xxsmall-negative);
  }
}
