.container {
  background: white;
  border: var(--space-xxsmall) solid var(--gray-xxlight-color);
  border-radius: var(--space-medium);
  padding: var(--space-medium);
  color: var(--gray-medium-color);
  display: flex;
  flex-direction: column;
  row-gap: var(--space-medium);
}

.hasExtraSpacing {
  row-gap: var(--space-normal);
}

.arrowIcon {
  color: var(--gray-light-color);
}

.upside {
  transform: rotate(180deg);
}
