.rightPanel {
  max-width: 35vmax;
  width: var(--space-right-panel-width);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
}

.rightPanelBorder {
  border-inline-start: 1px solid var(--gray-xlight-color);
  position: absolute;
  top: var(--space-menu-height);
  bottom: 0;
  z-index: var(--z-index-floating-right-panel);
}

.floatingRightPanel {
  overflow: auto;
  z-index: var(--z-index-floating-right-panel);
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
}

.rightPanelTitle {
  padding-inline: var(--space-normal);
  padding-block: var(--space-xlarge) var(--space-small);
  height: var(--space-menu-height);
  border-bottom: var(--space-xsmall) solid var(--gray-xxlight-color);
  overflow: visible;
}

.rightPanelContent {
  padding: 0;
  flex: 1;
  overflow: auto;
}
