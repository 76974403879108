.input {
  padding: var(--space-xxsmall) var(--space-small);
  border: none;
  border-radius: var(--space-xsmall);
  outline: var(--space-xxsmall) solid var(--gray-xlight-color);
  outline-offset: var(--space-xxsmall-negative);
  max-width: 50px;
  text-align: end;
  color: inherit;
  font-weight: inherit;

  &.inputError {
   outline-color: var(--danger-color);
  }
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  appearance: textfield;
}

.error {
  color: var(--danger-color);
  font-weight: 400;
  background-color: white;
  padding: var(--space-xsmall);
  z-index: 1;
}
