.mainTabs > * {
  text-transform: uppercase;
}

.mainTabsContent {
  margin: 0;
  padding: var(--space-normal);
  overflow: unset;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: var(--space-large);
  position: relative;
}

.loader {
  position: absolute;
  inset: 0;
  background-color: rgb(256 256 256 / 70%);
  display: flex;
  padding-block-start: var(--space-xlarge);
  justify-content: center;
  z-index: var(--z-index-loader);
}
