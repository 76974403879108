.tab[aria-selected="true"] {
  .tabBullet {
    background-color: var(--color-primary);

    &::before {
      content: "";
      display: block;
      border-radius: 50%;
      background-color: white;
      width: var(--space-small);
      height: var(--space-small);
    }
  }

  &.skin--button {
    background-color: var(--color-primary);
    
    * {
      color: white;
    }
  }
}

.tabBullet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--space-normal);
  height: var(--space-normal);
  border: var(--space-xxsmall) solid var(--gray-light-color);
  border-radius: 50%;
  background-color: white;
}

.key {
  display: flex;
  gap: var(--space-small);
  align-items: center;
  font-weight: 400 !important;
}

.value {
  font-weight: 500;
}
