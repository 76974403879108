.row {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-medium);
  cursor: pointer;
  width: 100%;
  background-color: var(--color-primary);
  border-radius: var(--space-small);
  height: 36px;
  padding-inline: var(--space-medium);
}

.rowExpanded {
  color: var(--text-color-inverse);
}

.certificateLabelContainer {
  display: flex;
  gap: var(--space-medium);
  align-items: center;
}

.certificateLabel {
  font-weight: 400;
  margin: 0;
}

.content {
  margin-block: var(--space-medium);
}
