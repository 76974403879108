.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    margin-top: var (--space-small);
    p {
        margin: 0 5px;
        color: #7B8591;
        font-weight: 700;
        &::before {
            content: '';
            display: inline-block;
            width: var(--space-regular);
            height: var(--space-regular);
            margin-inline-end: var(--space-regular);
        }
        
        &:nth-of-type(1) {
            &::before {
              background-color: var(--color-yellow-light);
            }
        }
        
        &:nth-of-type(2) {
            &::before {
              background-color: var(--color-green);
            }
        }
        
        &:nth-of-type(3) {
            &::before {
              background-color: var(--color-purple);
            }
        }
    }
}