.horizontalDivider {
  height: 1px;
  border-top: 1px solid var(--gray-xxlight-color);
  margin: 0;

  &.marginBottom--small {
    margin-bottom: var(--space-small);
  }

  &.marginBottom--medium {
    margin-bottom: var(--space-medium);
  }

  &.marginBottom--large {
    margin-bottom: var(--space-large);
  }

  &.marginTop--small {
    margin-top: var(--space-small);
  }

  &.marginTop--medium {
    margin-top: var(--space-medium);
  }

  &.marginTop--large {
    margin-top: var(--space-large);
  }

  &.marginLeft--small {
    margin-left: var(--space-small);
  }

  &.marginLeft--medium {
    margin-left: var(--space-medium);
  }

  &.marginLeft--large {
    margin-left: var(--space-large);
  }

  &.marginRight--small {
    margin-right: var(--space-small);
  }

  &.marginRight--medium {
    margin-right: var(--space-medium);
  }

  &.marginRight--large {
    margin-right: var(--space-large);
  }
}
