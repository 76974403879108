.tableRow {
  .requisite {
    font-weight: 700;
  }

  .requisite--PREREQ {
    color: var(--color-blue-light);
  }

  .requisite--CREDIT {
    color: var(--color-green-dark);
  }

  .ineligibleAchievement {
    display: flex;
    column-gap: var(--space-small);
    color: var(--error-color);
    justify-content: center;
    line-height: normal;
  }

  .tableCellCentered {
    text-align: center;
  }
}
