.container {
  &.itemContainer {
    background: var(--color-grey-light);
    border: 0;

    &.isExpanded {
      border: 1px solid var(--color-primary-light);
    }

    &.isHighlighted {
      background: var(--color-primary-xxlight);
    }

    .widgetContainer {
      background: transparent;
      gap: var(--space-medium);
      padding: 0;
      display: flex;
      margin-top: var(--space-medium-negative);

      & > :first-child {
        flex: 1;
      }
    }
  }
}

.indicatorValue {
  color: var(--color-primary);
  margin: 0;
  font-weight: 700;
  overflow: visible;
}
