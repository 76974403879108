.container.editable {
  display: flex;
  justify-content: space-between;
  gap: var(--space-small);

  > *:first-child {
    flex: 1 1 auto;
  }
}

.controlBtn {
  align-self: start;
  display: flex;
}
