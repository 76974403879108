@import "modules/layout/components/MainMenu/MainMenu.module";

.accordion {
  margin-inline-start: var(--space-medium-negative);
}

.accordionGroup {
  min-width: $sideMenuWidthExpanded * 1px;

  & > button {
    max-height: 55px;

    &:hover {
      background-color: var(--color-primary-light);
    }
  }
}

.accordionGroupActive {
  & > button {
    background-color: var(--color-primary-light);
  }
}
