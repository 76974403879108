.label {
  color: var(--color-primary);
  font-size: var(--font-size-xlarge);
  margin-block-end: var(--space-small);
}

.textarea {
  width: 100%;
  min-height: 25px;
  padding: var(--space-medium);
  border: 1px solid var(--color-primary-xxlight);
  border-radius: 4px;
  resize: none;
  font-size: var(--font-size-xlarge);
  color: var(--color-primary);

  &::placeholder {
    color: var(--color-primary-xlight);
    font-weight: 400;
  }

  &:focus-visible {
    outline: none;
  }
}
