.container {
    margin-bottom: var(--space-xxlarge);
    margin-top: var(--space-large);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    
    .leftSide {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: center;
        p {
            margin-inline-end: var(--space-large);
            margin-bottom: 0;
            font-weight: 400;
        }
    }
    p {
        margin-bottom: 0;
        font-weight: 400;
    }

    
}