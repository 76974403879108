.reactive-viewer {
  position: relative;
  width: 100%;
  user-select: none;
}

.reactive-viewer__inner,
.reactive-viewer__canvas {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.reactive-viewer,
.reactive-viewer * {
  overflow: visible;
}
