.secSideMenu {
  background-color: white;
  width: 310px;
  padding: var(--space-medium);
  display: flex;
  flex-direction: column;
  row-gap: var(--space-small);
  box-shadow: 0 0 11px 1px rgb(0 0 0 / 10%);

  > * {
    flex: 0 0 auto;
  }

  .secSideMenuContent {
    flex: 1 1 auto;
  }
}

.closeBtn {
  display: flex;
  margin-inline-start: auto;
}
