.container {
  display: flex;
  align-items: center;
  gap: var(--space-medium);

  &,
  & * {
    overflow: visible;
  }
}

.meterContainer {
  display: flex;
  flex: 1 1 100%;
  position: relative;
}

.meter {
  width: 100%;
  appearance: none;
  height: var(--space-small);
  border-radius: var(--space-small);
  min-width: var(--space-xxxlarge);
}

.customColors {
  height: 20px;

  .labelsContain {
    display: flex;

    .labelTitle,
    .labelGradiant {
      margin: 0;
      font-size: var(--font-size-regular);
      font-weight: 700;
      color: var(--primary-white, #fff);
      line-height: 20px;
      text-align: center;
    }

    .labelGradiant {
      &:last-child {
        border-top-right-radius: var(--space-small);
        border-bottom-right-radius: var(--space-small);
      }

      &:first-child {
        border-top-left-radius: var(--space-small);
        border-bottom-left-radius: var(--space-small);
      }
    }
  }

  .headersContain {
    display: flex;
    margin-block-start: -15px;

    .labelHeader {
      margin: 0;
      color: var(--color-primary);
      font-size: var(--font-size-large);
      font-weight: 400;
      text-align: center;
    }
  }
}
