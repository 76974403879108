.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-medium);
  width: 100%;
  height: 32px;
  border-radius: 4px;
  padding: var(--space-small) var(--space-medium);
  font-size: var(--font-size-large);

  & > :nth-child(1) {
    flex: 4.3;
  }

  & > :nth-child(2) {
    flex: 1;
  }

  & > :nth-child(3) {
    flex: 1.5;
  }
}

.headingRow {
  @extend .row;

  background-color: var(--color-primary);
  color: var(--text-color-inverse);
  font-weight: 700;
  margin-block-end: var(--space-medium);
}

.categoryHeadingRow {
  @extend .row;

  background-color: var(--color-primary-xxlight);
  color: var(--color-primary);
  font-weight: 700;
  cursor: pointer;

  & > :nth-child(1) {
    flex: 5;
  }

  & > :nth-child(2) {
    flex: 1.5;
    margin-inline-start: var(--space-xlarge);
  }
}

.chevron {
  width: auto;
  margin-inline-end: var(--space-small);
  transition: all 0.3s;
}

.chevronReversed {
  transform: rotate(180deg);
}

.resultContainer {
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  padding: var(--space-medium);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-large);
  font-weight: 700;
  margin-block: var(--space-medium);

  & > :nth-child(1) {
    color: var(--color-primary);
  }

  & > :nth-child(2) {
    color: var(--red-color);
  }
}
