.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-xxsmall);
  position: relative;
}

.gap--small {
  gap: var(--space-small);
}

.gap--medium {
  gap: var(--space-medium);
}

.gap--large {
  gap: var(--space-large);
}

.marginBottom--small {
  margin-bottom: var(--space-small);
}

.marginBottom--medium {
  margin-bottom: var(--space-medium);
}

.marginBottom--large {
  margin-bottom: var(--space-large);
}

.marginRight--small {
  margin-bottom: var(--space-small);
}

.marginRight--medium {
  margin-bottom: var(--space-medium);
}

.marginRight--large {
  margin-bottom: var(--space-large);
}

.vertical {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & .key {
    flex: 0 1 auto;
  }
}

.key {
  flex: 1 1 auto;
}

.value {
  color: var(--color-primary);
  font-size: var(--font-size-regular);
  font-weight: 700;
  white-space: nowrap;
  overflow: visible;
}

.growValue {
  flex: 1 1 auto;
  align-self: stretch;
  display: grid;
}

.inlinePlain {
  display: block;
}

.inlinePlain-key,
.header-key {
  color: var(--textColor-gray);
  font-weight: 400;
  font-size: var(--font-size-regular);
  display: inline;
  white-space: pre-wrap;
}

.inlinePlain-value {
  @extend .inlinePlain-key;

  color: var(--color-primary);
}

.header-key {
  width: 0px;
  margin-block-end: 0px;
  padding-top: 7px;
}

.header-value {
  align-items: center;
  flex: 1 1 380px;
}

.major-key {
  font-size: var(--font-size-large);
  margin-block-end: var(--space-medium);
}

.major-value {
  font-size: var(--font-size-xxlarge);
}

.large-key {
  font-size: var(--font-size-large);
}

.large-value {
  font-size: var(--font-size-large);
}

.regular-key {
  font-size: var(--font-size-regular);
}

.regular-value {
  font-size: var(--font-size-regular);
}

.tooltipContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.light {
  font-weight: 400;
}

.inline {
  justify-content: start;
  align-items: baseline;
  column-gap: var(--space-small);

  & > :first-child {
    flex: 0 0 auto;
  }
}

.mainData {
  color: var(--color-main-data);
  font-weight: 600;
}

.color--gray {
  color: var(--gray-medium-color) !important;
}

.color--primary {
  color: var(--color-primary) !important;
}

.color--white {
  color: var(--text-color-inverse);
}

.valueCollapsibleContainer {
  display: flex;
  gap: var(--space-medium);
  align-items: center;
}
