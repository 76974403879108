.recharts-surface {
  overflow: hidden !important;
}

.recharts-tooltip-wrapper {
  z-index: var(--z-index-rechart-tootlip-wrapper);
}

.para-icon {
  height: 1em;
  display: inline-block;
}

.ellipsis {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis-multi {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.tip-container {
  background-color: var(--background-color);
  padding: var(--space-small) var(--space-medium);
  border: 1px solid var(--light-border-color);
  z-index: var(--z-index-tip-container);
}

.modal-title.h4 {
  overflow: hidden;
}

.square-box {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.square-box::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.square-box > * {
  display: none;
}

.square-box > :first-child {
  display: inline-block;
  position: absolute;
  inset: 0;
  max-width: 100%;
  max-height: 100%;
}

.global-cursor-grabbing,
.global-cursor-grabbing * {
  cursor: grabbing !important;
}

.fa-hflip {
  transform: scaleX(-1);
}

.main-tip {
  background-color: var(--background-color);
  padding: var(--space-small) var(--space-medium);
  border: 1px solid var(--light-border-color);
  position: sticky;
  z-index: var(--z-index-main-tip);
}

/* used to place tips on circular/radial bar chart */
.placement-top {
  transform: translate(-50%, -100%);
}

.placement-right-end {
  transform: translateY(-100%);
}

.placement-right {
  transform: translateY(-50%);
}

.placement-bottom {
  transform: translate(-50%);
}

.placement-left-start {
  transform: translate(-100%);
}

.placement-left {
  transform: translate(-100%, -50%);
}

.placement-left-end {
  transform: translate(-100%, -100%);
}

.rtl,
.rtl * {
  direction: rtl;
  text-align: right;
}

.ltr,
.ltr * {
  direction: ltr;
  text-align: left;
}
