.container {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-medium);

  .withBorder {
    padding: var(--space-normal);
    border: var(--space-xxsmall) solid var(--gray-xxlight-color);
    border-radius: var(--space-medium);
  }

  .extraSpacing {
    row-gap: var(--space-large);
  }
}
