.container {
  visibility: hidden;
  position: absolute;
  padding: var(--space-xsmall);
  transition-duration: 200ms;
  top: 0;
  left: 0;
}

.groupContainer {
  position: absolute;
  transform: translate(-50%,-50%);
  border-radius: 50px;
  background-color: white;
  border: 1px solid var(--gray-xlight-color);
  padding: var(--space-small) var(--space-medium);
  box-shadow: 2px 0 5px var(--gray-lmedium-color);
}