.verticalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: var(--space-small);
}

.horizontalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 var(--space-small);
}

.row {
  @extend .horizontalContainer;

  flex: 1;
  padding: 0 0 0 var(--space-small);
}

.container {
  @extend .horizontalContainer;

  background-color: var(--color-primary-3xlight);
  border-radius: var(--space-small);
  border: 1px solid var(--color-primary-xxlight);
  color: var(--color-primary);
  font-size: var(--font-size-large);

  &:first-child {
    margin-block-start: var(--space-medium);
  }

  & button {
    font-size: var(--font-size-large);
  }
}

.signalsContainer {
  @extend .verticalContainer;

  width: 20px;
  background-color: var(--color-primary-light);
  margin-inline-start: var(--space-small-negative);
  border-radius: var(--space-small);
  justify-content: center;
  color: var(--background-color);
}

.separator {
  font-size: var(--font-size-xxlarge);
  margin-block-end: var(--space-normal-negative);
}

.actionsContainer {
  @extend .horizontalContainer;

  width: fit-content;
  gap: var(--space-small);
  align-items: flex-start;
}

.notAllowed {
  cursor: not-allowed !important;
  filter: opacity(0.8);
}
