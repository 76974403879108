.reactive-viewer__loader {
  position: absolute;
  z-index: var(--z-index-model-loader);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.3);
}

.reactive-viewer__loader > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
