.dropdownButton {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
}

.defaultButtonVariant {
  margin: 0;
  padding: var(--space-small) var(--space-medium);
  border-radius: var(--space-medium);
  border: 1px solid var(--light-border-color);
  outline: 0 !important;
  color: var(--text-color);
  background-color: var(--gray-xxlight-color);
  box-shadow: 0 0 5px -3px var(--dark-shadow-color);
}

.transparentButtonVariant {
  padding: 0;
  margin: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
}

.outlineButtonVariant {
  padding: var(--space-xxsmall);
  border-radius: var(--space-small);
  border: 1px solid var(--color-primary-xxlight);
  color: var(--color-primary);
  background-color: white;
  box-shadow: none;
}

.size--small {
  padding: var(--space-small);
}

.dropdownText {
  padding-inline: var(--space-small) var(--space-small);
  flex: 1;
  display: block;
  text-overflow: ellipsis;
}

.dropdownKnob {
  padding-inline-end: var(--space-small);
  font-size: var(--font-size-xxsmall);
}

.dropdownOptions {
  filter: drop-shadow(0 1px 4px rgb(0 0 0 / 25%));
  border-radius: 8px;
  background-color: var(--grey-container-transparent);
  padding: 0;
  list-style: none;
  z-index: var(--z-index-dropwdown);
}

.dropdownOption {
  display: flex;
  align-items: center;
  gap: var(--space-small);
  padding-block: var(--space-small);
  padding-inline: var(--space-medium) var(--space-normal);
  width: 100%;
  min-width: 60px;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-primary-xxlight);
  }
}

.dropdownOptionSelected {
  background-color: var(--color-primary-xxlight);
}
