.historyContainer {
  border: var(--space-xxsmall) solid var(--gray-light-color);
  border-radius: var(--space-small);
  padding: var(--space-medium);
  background-color: white;
  color: var(--gray-medium-color);
  font-weight: 400;
}

.historyTable {
  width: 100%;
  color: var(--color-primary);
  table-layout: fixed;

  &.historyTableSelectable tbody tr:hover {
    background-color: var(--color-primary-3xlight);
    cursor: pointer;
  }
}
