.layout {
  position: relative;
}

.layout__main {
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sideMenus {
  display: flex;
  position: fixed;
  inset-block: 0;
  inset-inline-start: 0;
  user-select: none;
  z-index: 13;
}

