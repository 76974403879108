.keyValueContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-medium);

  > *:first-child {
    flex: 1 1 auto;
  }

  .keyValue {
    font-size: var(--font-size-large);
  }
}

.icon {
  min-height: 1rem;
}
