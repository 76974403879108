.model__no-model-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  font-size: var(--font-size-xxlarge);
  font-weight: bold;
}
