:root {
  /* colors */
  --text-color: black;
  --text-color-inverse: white;
  --text-color-muted: #8393ba;
  --pv-progress-border-color: gray;
  --error-color: #d90000;
  --background-color: white;
  --translucent-bg-light: rgb(255 255 255 / 50%);
  --translucent-bg-gray: rgb(200 200 200 / 70%);
  --foreground-color: black;
  --item-slider-dot__background-color: lightgray;
  --item-slider-dot__background-color--selected: gray;
  --link-color: #0040ff;
  --blue-medium-color: #0040ff;
  --red-color: #f00;
  --orange-color: #d64c06;
  --xxxlight-blue-bg-color: #f6fbff;
  --dark-bg-color: #7a7a7a;
  --light-bg-color: #ddd;
  --light-border-color: #ddd;
  --gray-border-color: #c9d0e3;
  --dark-shadow-color: #666;
  --light-gb-color: #ddd;
  --light-xgb-color: #dddfdf;
  --gray-4xlight-color: #f9f9f9;
  --gray-3xlight-color: #f6f6f6;
  --gray-xxlight-color: #ececec;
  --gray-xlight-color: #ddd;
  --gray-light-color: #c3c3c3;
  --gray-xlmedium-color: #f5f5f5;
  --gray-lmedium-color: #959595;
  --gray-blue-light-color: #7b8591;
  --gray-blue-regular-color: #46466a;
  --gray-medium-color: #7a7a7a; /* grey 2 */
  --gray-dark-color: #666;
  --gray-sdark-color: #676868;
  --gray-mdark-color: #595959;
  --gray-xmdark-color: #949494;
  --gray-xdark-color: #484848;
  --gray-xxdark-color: #222;
  --gray-xxxdark-color: #0a0a0a;
  --gray-4xdark-color: #171717;
  --gray-5xdark-color: #1f2937;
  --primary-color: rgb(63 120 180);
  --info-color: rgb(23 162 184);
  --danger-color: #dc2626;
  --success-color: rgb(40 167 69);
  --warn-color: rgb(255 221 85);
  --color-warn-dark: #856404;
  --color-catskill-white: #e3e7f1;
  --purple-color: #8a56a3;
  --yellow-color: #eab308;
  --lighting-yellow-color: #ffe2b2;
  --green-color: #c4e6a0;
  --color-green-light: #c7e9b6;
  --color-green-haze: #81cdbb;
  --color-green-medium: #6dc111;
  --light-blue-color: #00aeef;
  --white-lilac: #edf0f8;
  --potential-savings__text-color: #16a7f7;
  --potential-savings__background-color: #06bfff;
  --spending__background-color: #606060;
  --recommendation-count__background-color: #606060;
  --tip__background-color: wheat;
  --occupancy-insights__background-color: #f37e5f;
  --badge-info-text-color: #0040ff;
  --badge-info-background-color: #e0e8ff;
  --badge-warning-text-color: #663f00;
  --badge-warning-background-color: #ffe2b2;
  --badge-danger-text-color: #f00;
  --badge-danger-background-color: #ffeced;
  --badge-primary-text-color: #fff;
  --badge-primary-background-color: #2e81ff;
  --badge-basic-text-color: #484848;
  --badge-basic-background-color: #fff;
  --chart-red-color: rgb(228 37 60);
  --chart-orange-color: rgb(251 173 65);
  --chart-purple-color: rgb(82 107 164);
  --chart-pink-color: rgb(240 76 143);
  --chart-green-color: rgb(0 166 148);
  --modal-overlay: rgba(0, 0, 0, 0.1);
  --time-picker-color: #213447;
  --time-picker-background-color: #f0f3f7;
  --time-picker-AMPM-border: #0000000a;
  --time-picker-AMPM-shadow: #0000001f;
  --color-inactive-graph: rgba(217, 219, 222, 0.4);
  --color-blue-link: #2563eb;
  --priority-high: #ff3e4e;
  --priority-medium: #ff9e00;
  --priority-low: #194af4;
  --pie-chart-blue: #4472c5;
  --pie-chart-grey: #a0a0a0;
  --pie-chart-orange: #ed7d31;
  --pie-chart-green: #16a34a;
  --pie-chart-green-olive: #9cc544;
  --pie-chart-blue-light: #75c5ff;
  --pie-chart-lilac: #af5fff;
  --pie-chart-orange-light: #f3b808;
  --inactive-tab: #94a3b8;

  /* spaces */
  --space-xxxxlarge: 55px;
  --space-xxxlarge: 48px;
  --space-xxlarge: 32px;
  --space-xlarge: 24px;
  --space-larger: 20px;
  --space-large: 16px;
  --space-xnormal: 14px;
  --space-normal: 12px;
  --space-regular: 10px;
  --space-medium: 8px;
  --space-xmedium: 6px;
  --space-small: 4px;
  --space-xsmall: 2px;
  --space-xxsmall: 1px;
  --space-xxxxlarge-negative: -88px;
  --space-xxxlarge-negative: -48px;
  --space-xxlarge-negative: -32px;
  --space-xlarge-negative: -24px;
  --space-large-negative: -16px;
  --space-normal-negative: -12px;
  --space-regular-negative: -10px;
  --space-medium-negative: -8px;
  --space-smedium-negative: -6px;
  --space-small-negative: -4px;
  --space-xsmall-negative: -2px;
  --space-xxsmall-negative: -1px;
  --space-menu-height: 66px;
  --space-right-panel-width: 381px;

  /* sizes */
  --size-icon: 50px;
  --font-size-4xsmall: 0.4rem; /* 4px */
  --font-size-3xsmall: 0.5rem; /* 5px */
  --font-size-xxsmall: 0.6rem; /* 6px */
  --font-size-xsmall: 0.7rem; /* 7px */
  --font-size-small: 0.8rem; /* 8px */
  --font-size-medium: 0.9rem; /* 9px */
  --font-size-regular: 1rem; /* 10px */
  --font-size-large: 1.2rem; /* 12px */
  --font-size-xlarge: 1.35rem; /* 13px */
  --font-size-1_2xlarge: 1.4rem; /* 14px */
  --font-size-xxlarge: 1.5rem; /* 15px */
  --font-size-2xlarge: 1.6rem; /* 16px */
  --font-size-3xlarge: 1.8rem; /* 18px */
  --font-size-4xlarge: 2rem; /* 20px */
  --font-size-5xlarge: 2.25rem; /* 22px */
  --font-size-6xlarge: 2.5rem; /* 25px */
  --font-size-7xlarge: 3.6rem; /* 36px */

  --textColor-gray: #666;

  /* font weight */
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  /* NEW THEME */
  /* color */
  --color-primary-4xlight: #f3f5ff;
  --color-primary-3xlight: #f2f2fd;
  --color-primary-xxlight: #cfd5e7;
  --color-primary-xlight: #aeaecc;
  --color-primary-light: #8f9bba;
  --color-primary: #304375;
  --color-primary-muted: #3d5183;
  --color-primary-dark: #26293a;
  --color-primary-text: #344054;
  --color-primary-background: #f0f4ff;
  --color-main-data: #772983; /* color used to as text color to highlight certain data points */
  --color-accent-light-blue: #00bfff;
  --color-accent-blue: #0040ff; /* para blue */
  --color-active: #49dd69; /* para success color */
  --color-success: #96f3c6;
  --color-limeade: #56a800;
  --color-orange-white: #fefce8;
  --color-rich-gold: #a16207;
  --color-scandal: #dcfce7;
  --color-watercourse: #047857;
  --color-purple: #8a56a3;
  --color-purple-dark: #c50ec9;
  --color-yellow-light: #f1da89;
  --color-green: #c4e6a0;
  --color-green-dark: #84cc16;
  --color-blue-light: #2490be;
  --color-blue-light-medium: #7bcfff;
  --color-blue-light-dark: #2e81ff;
  --color-blue-dark: #245da6;
  --color-blue-dark-medium: #3454a5;
  --color-blue-dark-regular: #1e3a8a;
  --color-blue-regular-color: #4c6091;
  --color-red-fog: #fee2e2;
  --color-red-pale: #f46e6e;
  --color-red-medium: #ed6a5e;
  --color-grey-light: #f0f1f3;
  --color-gray-blue: #8393ba;
  --color-uncertified: #bfbfbf;
  --color-certified-v1: #adc686;
  --color-certified-v2: #8da46a;
  --color-silver-v1: #77b9c3;
  --color-silver-v2: #578e98;
  --color-gold-v1: #979452;
  --color-gold-v2: #d8e29c;
  --color-platinum-v1: #76888a;
  --color-platinum-v2: #c6cfd0;
  --recommendation-annotation-color: #fbac35;
  --calendar-disabled: #f0f0f0;
  --calendar-disabled-font: #b7b7b7;
  --calendar-fixed-range-hover: #e6e6e6;
  --calendar-fixed-range-active-hover: #1087ff;
  --grey-container: #f6f6f6;
  --grey-container-transparent: #f6f6f6cc;
  --interval-graph-background: #f2f6fe;
  --graph-columns: #f0f1f3;
  --color-feta: #f0fdf4;
  --color-provincial-pink: #fef2f2;
  --color-gray-mystic: #e0e7ed;
  --color-athens-gray: #f3f4f6;
  --color-mine-shaft: #252525;

  /* border-radius */
  --border-radius-small: 2px;
  --border-radius-medium: 4px;
  --border-radius-regular: 6px;
  --border-radius-normal: 8px;
  --border-radius-lnormal: 12px;
  --border-radius-large: 14px;

  /* z-index */
  --z-index-modal-header: 20000;
  --z-index-loader: 10000;
  --z-index-popover: 1000;
  --z-index-info-popover: 1000;
  --z-index-notifier: 1000;
  --z-index-main-tip: 1000;
  --z-index-level2-tip: 1000;
  --z-index-tip-container: 1000;
  --z-index-global-loader-container: 1000;
  --z-index-toast-container: 1000;
  --z-index-bottom-panel: 1000;
  --z-index-header: 1000;
  --z-index-date-selector: 100;
  --z-index-item-slider-dots-container: 100;
  --z-index-annotation-popover: 100;
  --z-index-model-side-annotations: 100;
  --z-index-data-panel-content: 100;
  --z-index-dropwdown: 10;
  --z-index-floating-right-panel: 10;
  --z-index-mouse-annotation: 10;
  --z-index-modal: 100;
  --z-index-asset-management-controls: 1;
  --z-index-tab-header: 1;
  --z-index-tabs-container: 1;
  --z-index-recommentation-annotation-group: 1;
  --z-index-model-loader: 1;
  --z-index-model-mini-loader: 1;
  --z-index-rechart-tootlip-wrapper: 1;
  --z-index-slider-thumb: 1;
  --z-index--gant-slider-selected-track: 1;
  --z-index--gant-slider-selected-cursor: 100;
  --z-index--gant-slider-cursor: 1000;
  --z-index-bottom-panel: 12;
  --z-index-tooltip: 15;
  --z-index-small-tooltip: 2;
  --z-index-input-icon: 2;
  --z-index-header-recommendations: 2;
  --z-index-dashboard-header: 3;
}
