.popoverContainer {
  overflow: visible;
  transition-duration: 350ms;
  display: inline-flex;
}

.icon {
  box-shadow: rgb(255 255 255 / 50%) 0 0 10px 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  width: 250px;
  max-width: 50vw;
}
