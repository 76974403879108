.searchContainer {
  display: flex;
  align-items: center;
  width: 80%;
  gap: var(--space-medium);
  margin-block-end: var(--space-large);
}

.radioInputContainer {
  margin-block-end: var(--space-large);
}

.actionButton {
  font-weight: 400;
  margin-inline-start: var(--space-medium);
}
