.selectLabel {
  display: block;
  padding-block-end: var(--space-small);
}

.required {
  color: var(--danger-color);
}

.selectInput {
  position: relative;

  select {
    appearance: none;
    color: var(--color-primary-xlight);
    width: 100%;
    padding: var(--space-medium);
    border: 1px solid;
    border-radius: var(--space-small);
    outline: none;
    box-shadow: none;
    
    &[aria-invalid="true"] {
      border-color: var(--danger-color);
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    inset-block-start: 10px;
    inset-inline-end: var(--space-normal);
    width: var(--space-medium);
    height: var(--space-medium);
    border-inline-end: var(--space-xsmall) solid var(--color-primary-xlight);
    border-block-end: var(--space-xsmall) solid var(--color-primary-xlight);
    transform: rotate(45deg);
  }
}
