.chartContainer {
  width: 100%;
  height: 15px;
  display: flex;
  overflow: visible;

  & > * {
    overflow: visible;
  }
}

.area {
  height: 100%;

  &:first-child {
    border-start-start-radius: var(--space-small);
    border-end-start-radius: var(--space-small);
  }

  &:last-child {
    border-start-end-radius: var(--space-small);
    border-end-end-radius: var(--space-small);
  }
}

.areaHighlighted {
  opacity: 0.8px;
}

.legendContainer {
  display: flex;
  justify-content: center;
  column-gap: var(--space-large);
  flex-wrap: wrap;
  padding: var(--space-small);
}

.legendItem {
  display: flex;
  align-items: center;
  margin: var(--space-xsmall);
}

.legendItem--selected {
  font-weight: 700;
}

.legendItem--no-pointer {
  pointer-events: none;
}

.legendColor {
  width: var(--space-medium);
  height: var(--space-medium);
  border-radius: 50%;
  margin-right: var(--space-small);
}

.expandText {
  width: 100%;
  display: flex;
  justify-content: center;
  font-style: italic;
}
