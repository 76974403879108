.pointer {
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: 0;
  font-size: var(--font-size-xxsmall);
  transform: translate(-50%, 100%);
  transition-duration: 250ms;
}

.borderHashed::before,
.borderLine::before {
  content: "";
  margin-block-start: -20px;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 20px;
  transform: translate(calc(50% - 1px), 0);
}

.borderHashed {
  &::before {
    border-left: var(--space-xsmall) dashed var(--blue-medium-color);
  }
}

.borderLine {
  &::before {
    border-left: var(--space-xsmall) solid var(--text-color);
  }
}

.chevronCurrent {
  color: var(--text-color);
  height: 2em;
}

.chevronTarget {
  color: var(--blue-medium-color);
  height: 2em;
}
