.stage {
  --dot-diameter: 5px;
  --dot-placements: calc(var(--dot-diameter) + (var(--dot-diameter) / 2));

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: fit-content;
  width: 100%;

  &.blockSpace {
    margin-block: var(--space-small);
  }

  &.inline {
    display: inline-flex;
    width: 30px;
  }
}

.dot {
  position: relative;
  width: var(--dot-diameter);
  height: var(--dot-diameter);
  border-radius: calc(var(--dot-diameter) / 2);
  background-color: currentcolor;
  color: currentcolor;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  overflow: unset;
  flex: 0 0 auto;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    flex: 0 0 auto;
    width: var(--dot-diameter);
    height: var(--dot-diameter);
    border-radius: calc(var(--dot-diameter) / 2);
    background-color: currentcolor;
    color: currentcolor;
    animation: dot-flashing 1s infinite alternate;
  }

  &::before {
    left: calc(var(--dot-placements) * -1);
    animation-delay: 0s;
  }

  &::after {
    left: var(--dot-placements);
    animation-delay: 1s;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: currentcolor;
  }

  50%,
  100% {
    background-color: rgb(0 0 0 / 10%);
  }
}
