.keyValue {
  .key,
  .value {
    font-size: var(--font-size-large);
  }

  .value {
    padding-inline-start: var(--space-small);
  }

  .fieldset {
    display: flex;
    column-gap: var(--space-normal);
    padding-inline-start: var(--space-small);
    padding-block-start: var(--space-medium);

    > * {
      display: flex;
      align-items: center;
      column-gap: var(--space-small);
    }
  }
}
