.headerText {
  font-size: var(--font-size-xlarge);
  font-weight: 600;
  margin: 0;
}

.hint {
  color: var(--color-primary-light);
  font-size: var(--font-size-large);
  margin-block-end: var(--space-xlarge);
}

.modalBody {
  max-height: 75vh;
  overflow: auto;
}

.modalContents {
  display: flex;
  flex-direction: column;
  gap: var(--space-large);
}

.actionButton {
  font-weight: 400;
  margin-inline-start: var(--space-medium);
}
