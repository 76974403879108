.container {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-medium);
}

.stacksWithValueContainer {
  display: flex;
  align-items: center;
  gap: var(--space-medium);

  &,
  & * {
    overflow: visible;
  }
}

.stacksContainer {
  display: flex;
  flex: 1 1 100%;
  border-radius: 5px;
  height: 5px;
  min-width: var(--space-xxxlarge);
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.tooltip {
  white-space: nowrap;
}

.legend {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: var(--space-small);
  font-weight: 400;
  font-size: var(--font-size-medium);
}

.legendBullet {
  width: 7px;
}
