.titleContainer {
  display: flex;
  justify-content: space-between;
}

.fieldContainer {
  padding: var(--space-medium) var(--space-normal) !important;
}

.pvFieldContainer {
  border-color: var(--color-success) !important;
}

.totalFieldContainer {
  background-color: var(--color-primary-light);
}

.totalFieldText {
  color: var(--text-color-inverse) !important;
}

.popoverContainer {
  background: var(--color-primary);
  border: 0;
  color: var(--text-color-inverse);
  padding: var(--space-medium) var(--space-large);
  max-width: 180px;
  white-space: break-spaces;
  font-weight: 400;
}
