.container {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-inline-start: 30%;
  cursor: pointer;

  &:hover:not([aria-disabled="true"]) {
    color: var(--color-primary);
    background-color: var(--color-primary-light);
  }
}

.disabled {
  cursor: not-allowed;
}

.label {
  font-weight: 300;
  margin: 0;
}

.link {
  &:hover {
    text-decoration: initial;
  }
}

.linkActive {
  & > .container {
    color: var(--color-primary);
    background-color: var(--color-primary-light);
  }
}
