.tab {
  color: var(--color-primary);
  border: none;
  outline: none;
  font-size: var(--font-size-medium);
  display: flex;
  align-items: center;
}

.default {
  background-color: white;
  border-radius: var(--space-small);
  padding: var(--space-medium);
  justify-content: center;

  &[aria-selected="true"] {
    color: white;
    background-color: var(--color-primary);
    font-weight: 700;
  }
}

.rounded {
  text-align: center;
  justify-content: center;
  padding: var(--space-medium) var(--space-normal);
  background-color: var(--gray-3xlight-color);
  border-radius: 50rem;
  font-weight: 400;

  &[aria-selected="true"] {
    color: white;
    background-color: var(--color-primary);
    font-weight: 700;
  }
}

.underlined {
  padding: var(--space-small);
  background-color: transparent;
  color: var(--gray-light-color);
  border-block-end: var(--space-xsmall) solid;
  justify-content: center;
  font-weight: 500;
  border-radius: 0;

  &[aria-selected="true"] {
    color: var(--color-primary);
    border-block-end: var(--space-xsmall) solid;
    font-weight: 700;
  }
}

.box {
  background-color: white;
  padding: var(--space-normal) var(--space-small);
  justify-content: center;
  text-align: center;
  font-weight: 700;
  border: var(--space-xxsmall) solid var(--color-primary-xxlight);

  &[aria-selected="true"] {
    background-color: var(--color-primary-xxlight);
  }
}

.sleek {
  @extend .default;

  background-color: var(--color-primary-xxlight);
  font-weight: 300;
  color: var(--text-color-muted);

  &[aria-selected="true"] {
    background-color: var(--color-primary-muted);
    font-weight: 400;
  }
}

.sizesmall {
  padding: var(--space-small);
}
