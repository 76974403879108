.container {
  margin-bottom: var(--space-medium);
}

.titleContainer {
  display: flex;
  column-gap: var(--space-small);
  align-items: center;
}

.externalLink {
  display: flex;
}
