.container {
  display: flex;
  align-items: center;
  gap: var(--space-medium);
  margin: var(--space-medium) 0;

  button {
    width: 100%;
  }
}

.fade {
  color: var(--gray-light-color);
}

.options {
  min-width: 150px;
}

.dropdownContainer {
  flex: 1;
}

.label {
  color: var(--gray-lmedium-color);
}
