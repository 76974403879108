.loader {
  border-radius: 50%;
  animation: loader-rotate 1s linear infinite;
  user-select: none;
}
@keyframes loader-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}