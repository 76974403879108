@media only print {
  .global-loader-container {
    display: none !important;
  }
}
.global-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-global-loader-container);
  background-color: rgba(0, 0, 0, 0.25);
}

.global-loader-container > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 50%;
  background-color: rgba(255,255,255,0.8);
  user-select: none;
}