.dropdownButton {
  border-color: var(--gray-light-color);
  color: var(--gray-xdark-color);
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & p {
    margin: 0;
    display: inline-block;
    margin-inline-start: var(--space-medium);
  }
}
